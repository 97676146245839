@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

@import "colors";
@import "style-mixin";

/*
11px = 0.69rem;
12px = 0.75rem;
13px = 0.813rem;
14px = 0.875rem
15px = 0.938rem
18px = 1.125rem
*/
$btnMap: (
  "primary-dark": $link-color,
  "secondary": $textSecondary,
  "purple": $default-purple,
  "orange": $default-orange,
  "red": $default-red,
  "main-red": $main-red,
  "removed": $removed-color,
  "green": $darker-green,
  "approved": $approved-color
);

html,
body {
  background: $page-bg-color;
  font-family: "Helvetica Neue", sans-serif;
}

h5 {
  font-size: 1.125rem;
  font-family: HelveticaNeue-Medium;
}

a {
  color: $link-color;
}

.show-pointer {
  cursor: pointer;
}

label {
  color: $textPrimary;
}

::placeholder {
  color: $textSecondary;
}

.font-weight-500 {
  font-weight: 500;
}

/* LAYOUT */
.header-icon {
  max-width: 150px;
}

.col-border-right {
  @include col-border;
  min-height: 100vh !important;
}

.col-border-bottom {
  @include col-border-bottom;
}

.scrollable-content {
  height: calc(100vh - 200px);
  overflow: auto;
}

/* TEXT OVERRIDES*/
.text-primary {
  color: $textPrimary;
}

.text-secondary {
  color: $textSecondary;
}
/*
.text-success
.text-danger
.text-warning
.text-info
*/

/* BORDER OVERRIDES */
.border-secondary {
  border-color: $border-secondary !important;
}

/* CUSTOM Component */
.form-group {
  &.text-danger {
    label {
      color: $error-red !important;
    }
  }
  .form-control {
    border-radius: 0.125rem !important;
    border-color: $border-secondary !important;
    color: $textPrimary;
    font-size: 0.938rem;
    &.is-invalid {
      border-color: $error-red !important;
    }
    &.add-remove-icons {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  &.add-remove-icons input,
  &.add-remove-icons {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .editor-toolbar {
    border-top-right-radius: 2px !important;
    border-top-left-radius: 2px !important;
    &:before,
    &:after {
      margin: 0 6px !important;
    }
  }
}

.form-control,
.btn {
  border-radius: 5px;
}

.form-control {
  &:focus {
    box-shadow: none;
  }
}

.btn-link {
  color: $link-color;
}

.btn-dark-gray {
  color: $dark-gray;
}

.bg-dark-gray {
  background-color: $dark-gray;
}

.bg-other-green {
  background-color: $green;
}

.bg-transparent {
  background-color: transparent !important;
}

.text-dark-gray {
  color: $dark-gray;
}

.text-darkest-gray {
  color: $gray-900 !important;
}

.text-light-gray {
  color: $light-gray;
}

.text-green {
  color: $green;
}

.btn.nav-btn {
  background-color: $link-color;
  border-color: $link-color;
  color: #fff;
}

.btn-lg {
  padding: 0 1.5rem;
  font-size: 16px;
  font-weight: 500;
  height: 46px;
  line-height: 40px;
  border-radius: 5px;
  border-width: 0;
}

.btn-md {
  padding: 0 1.25rem;
  font-size: 14px;
  font-weight: 500;
  height: 34px;
  border-radius: 5px;
  line-height: 30px;
  min-width: 90px;
}

.btn-sm {
  font-weight: 500;
  border-radius: 5px;
}

.btn-round {
  border-radius: 50%;
  line-height: 1rem;
  padding: 0;
  width: 38px;
  height: 38px;
  &:disabled {
    opacity: 0.2;
  }
  &.btn-approved {
    background-color: $approved-color;
    border-color: $approved-color;
  }
  &.btn-flagged {
    background-color: $flagged-color;
    border-color: $flagged-color;
  }
  &.btn-removed {
    background-color: $removed-color;
    border-color: $removed-color;
  }
  &.btn-reported {
    background-color: $reported-color;
    border-color: $reported-color;
  }
  &.btn-pending {
    background-color: $pending-color;
    border-color: $pending-color;
  }
}

.alert-light-blue {
  color: $textPrimary;
  background-color: $table-active;
  border-color: $table-active;
  font-weight: 500;
}

.btn-light {
  color: $medium-blue;
  background-color: rgba($medium-blue, 0.07);
  border-color: rgba($medium-blue, 0.07);

  &:hover {
    color: $medium-blue;
    background-color: darken(rgba($medium-blue, 0.07), 10%);
    border-color: darken(rgba($medium-blue, 0.07), 10%);
  }
}
.btn-white {
  color: $textSecondary;
  background-color: #ffffff;
  border-color: #ffffff;

  &:hover {
    color: darken($textSecondary, 10%);
    background-color: #ffffff;
    border-color: #ffffff;
  }
}

// Generate various button color classes
@each $name, $color in $btnMap {
  .btn-#{$name} {
    color: #ffffff !important;
    background-color: $color !important;
    border-color: $color !important;

    &:hover {
      color: #ffffff !important;
      background-color: darken($color, 10%) !important;
      border-color: darken($color, 10%) !important;
    }
  }
  .bg-#{$name} {
    color: #ffffff;
    background-color: $color;
    border-color: $color;
  }
}
/* NAVIGATION*/
.nav-tabs {
  border-bottom: 1px solid transparent;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link.active {
  color: $bg-primary;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-tabs .nav-link.active {
  background-color: $bg-primary;
  border-color: $bg-primary;
  color: white;
}

.tab-content {
  height: inherit;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.cursor-grab {
  cursor: grab;
}

// Navigation components
.global-left-nav {
  &.mobile {
    &.dropdown {
      > .dropdown-toggle {
        font-size: 1rem !important;
        &:before {
          margin-right: 0.5rem;
        }
      }
      .dropdown-menu .dropdown-item,
      .dropdown-menu .dropdown-item .nav-link {
        line-height: 0.1rem;
        padding-bottom: 10px;
      }
    }
  }
  .nav-item,
  .dropdown-menu {
    .nav-link,
    .dropdown-item {
      font-size: 0.938rem;
      line-height: 1.3em;
      text-indent: -6px;
      color: $textPrimary;
      &:hover {
        color: $textPrimary;
        background-color: $navBgColor;
      }
      &.active {
        background-color: $navBgColor;
        color: black;
        font-weight: 500;
      }

      &.suggested:before {
        color: $suggested-color;
      }

      &.flagged:before {
        color: $flagged-color;
      }

      &.reported:before {
        color: $reported-color;
      }

      &:before {
        @include colored-dot;
        position: relative;
        top: 4px;
        color: transparent;
        display: inline-block;
        width: 1em;
        font-size: 1.8em;
        font-weight: normal !important;
        margin: 0 -19px 0 0;
      }
    }
  }
}

.pagination {
  margin-bottom: 0;
  color: $textSecondary;
  font-size: 0.875rem;
  .nav-link.disabled {
    color: lighten($textSecondary, 20%);
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: lighten($textSecondary, 20%);
      cursor: default;
    }
  }
  .nav-link {
    color: $textSecondary;
    display: inline-flex;
    align-items: center;
    border: 0;
    font-size: 0.875rem;
    white-space: nowrap;
    padding: 0;
    > div {
      font-size: 28px;
      position: relative;
      bottom: 2px;
    }
    &:hover {
      text-decoration: none;
      color: darken($textSecondary, 50%);
    }
    &:active {
      text-decoration: none;
      color: lighten($textSecondary, 30%);
    }
  }
}

.img-thumbnail {
  border: 0px solid transparent;
  border-radius: 0.25rem;
  padding: 0;
  margin: 0;
  &.active {
    border-color: #00b7bd;
  }
}

.badge {
  padding-bottom: 4px;
  justify-content: space-between;
  svg {
    width: 1em !important;
    height: 1em !important;
    border-radius: 50%;
    margin-right: 6px;
    border: solid 1px #fff;
    background-color: transparent;
  }
  &.right svg {
    margin-right: 0;
  }

  &.reported,
  &.reported svg {
    background-color: $reported-color;
  }
  &.approved,
  &.approved svg {
    background-color: $approved-color;
  }
  &.removed,
  &.removed svg {
    background-color: $removed-color;
  }
  &.flagged,
  &.flagged svg {
    background-color: $flagged-color;
  }
  &.pending_approval_flagged,
  &.pending_approval_flagged svg {
    background-color: $flagged-color;
  }
  &.suggested,
  &.suggested svg {
    background-color: $suggested-color;
  }
  &.danger,
  &.danger svg {
    background-color: $suggested-color;
  }
  &.pending_approval,
  &.pending_approval svg {
    background-color: $pending-color;
  }
}

/*CARD*/
.case-author-detail {
  .partner-case-header {
    text-transform: uppercase;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    padding: 4px;
    @include text-13 background-image:
      linear-gradient(98deg, $bg-primary 1%, #3bafa8 100%);
  }

  .card-body {
    font-size: 0.875rem;
  }
  .card-text {
    font-size: 0.813rem;
  }
}

/* COMMENT */
.comment-card {
  font-size: 0.875rem;
}

/* DROPDOWN */
.dropdown {
  button {
    font-size: 0.813rem;
    &.focus,
    &:focus {
      box-shadow: none;
    }
  }

  .dropdown-item {
    color: $textPrimary;
    &:hover {
      background-color: #eef1f7;
    }

    &.active {
      background-color: $page-bg-color;
      color: $textPrimary;
    }
  }

  &.lg {
    button {
      color: $textPrimary;
      font-size: 1rem;
    }

    .dropdown-item {
      button {
        font-size: 0.938rem;
      }
    }

    .dropdown-toggle::after {
      margin-left: 1rem;
      vertical-align: 0;
      border-top-color: $link-color;
    }
  }
}

/* LIST GROUP */
.list-group.verification-author {
  .list-group-item {
    border-left-width: 0;
    border-right-width: 0;
    padding: 0.75rem 1rem;
    .fa-star {
      position: absolute;
      left: 9px;
      margin-top: 23px;
    }
    p {
      line-height: 1;
      margin-top: 3px;
    }
    &.user-active {
      background-color: $page-bg-color;
      color: $textPrimary;
    }
    &.bulk-active {
      background-color: lighten($bg-verification-bulk-active, 5%);
      color: $textPrimary;
    }
    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

/* TABLE */
.table {
  font-weight: normal;
  background-color: #fff;
  border: none;
  thead {
    border: none;
    th {
      border: none;
    }
  }
  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent;
  }
}
.column-active {
  background-color: $page-bg-color;
}

.force-show-scrollbars ::-webkit-scrollbar-track:vertical {
  border-left: 1px solid #e7e7e7;
  box-shadow: 1px 0 1px 0 #f6f6f6 inset, -1px 0 1px 0 #f6f6f6 inset;
}

.force-show-scrollbars ::-webkit-scrollbar-track:horizontal {
  border-top: 1px solid #e7e7e7;
  box-shadow: 0 1px 1px 0 #f6f6f6 inset, 0 -1px 1px 0 #f6f6f6 inset;
}

.force-show-scrollbars ::-webkit-scrollbar {
  -webkit-appearance: none;
  background-color: #fafafa;
  width: 16px;
}

.force-show-scrollbars ::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #c1c1c1;
  border-color: transparent;
  border-radius: 9px 8px 8px 9px;
  border-style: solid;
  border-width: 3px 3px 3px 4px; /* Workaround because margins aren't supported */
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Unfortunately scrollbars can't use CSS transitions. Also, it's not possible
   to highlight the thumb when the scrollbar track is hovered without some
   JavaScript acrobatics; https://jsfiddle.net/QcqBM/6/ is a start, but you
   also have to check whether the element has a scrollbar and if so how wide
   it is. */
.force-show-scrollbars ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.search-box {
  input.form-control {
    border-color: #ffffff;
    outline: none;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &.lg {
    input.form-control {
      height: 46px;
    }
  }
}

$rbt-color-primary: #007bff !default;
$rbt-color-disabled: #495057 !default;
$rbt-color-white: #fff !default;

/**
 * Multi-select Input
 */
$rbt-background-color-disabled: #e9ecef !default;

$rbt-border-color-focus: #80bdff !default;
$rbt-border-color-focus-invalid: #dc3545 !default;
$rbt-border-color-focus-valid: #28a745 !default;

$rbt-box-shadow-dimensions: 0 0 0 0.2rem;
$rbt-box-shadow-color: rgba(0, 123, 255, 0.25) !default;
$rbt-box-shadow-color-invalid: rgba(220, 53, 69, 0.25) !default;
$rbt-box-shadow-color-valid: rgba(40, 167, 69, 0.25) !default;

$rbt-color-focus: #495057 !default;
$rbt-placeholder-color: #6c757d !default;

.rbt-input-multi {
  cursor: text;
  overflow: hidden;
  position: relative;

  // Apply Bootstrap focus styles
  &.focus {
    border-color: $rbt-border-color-focus;
    box-shadow: $rbt-box-shadow-dimensions $rbt-box-shadow-color;
    color: $rbt-color-focus;
    outline: 0;
  }

  &.form-control {
    height: auto;
  }

  // BS4 uses the :disabled pseudo-class, which doesn't work with non-inputs.
  &.form-control[disabled] {
    background-color: $rbt-background-color-disabled;
    opacity: 1;
  }

  &.is-invalid.focus {
    border-color: $rbt-border-color-focus-invalid;
    box-shadow: $rbt-box-shadow-dimensions $rbt-box-shadow-color-invalid;
  }

  &.is-valid.focus {
    border-color: $rbt-border-color-focus-valid;
    box-shadow: $rbt-box-shadow-dimensions $rbt-box-shadow-color-valid;
  }

  // Apply Bootstrap placeholder styles
  input {
    // Firefox
    &::-moz-placeholder {
      color: $rbt-placeholder-color;
      opacity: 1;
    }

    // Internet Explorer 10+
    &:-ms-input-placeholder {
      color: $rbt-placeholder-color;
    }

    // Safari and Chrome
    &::-webkit-input-placeholder {
      color: $rbt-placeholder-color;
    }
  }

  .rbt-input-wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
    margin-top: -1px;
    overflow: hidden;
  }

  .rbt-input-main {
    margin: 1px 0 4px;
  }
}

/**
 * Close Button
 */
.rbt-close {
  z-index: 1;

  &-lg {
    font-size: 24px;
  }
}

/**
 * Token
 */
$rbt-token-background-color: #e7f4ff !default;
$rbt-token-color: $rbt-color-primary !default;

$rbt-token-disabled-background-color: rgba(0, 0, 0, 0.1) !default;
$rbt-token-disabled-color: $rbt-color-disabled !default;

$rbt-token-active-background-color: $rbt-color-primary !default;
$rbt-token-active-color: $rbt-color-white !default;

.rbt-token {
  background-color: $rbt-token-background-color;
  border: 0;
  border-radius: 0.25rem;
  color: $rbt-token-color;
  display: inline-block;
  line-height: 1em;
  margin: 1px 3px 2px 0;
  padding: 4px 7px;
  position: relative;

  &-disabled {
    background-color: $rbt-token-disabled-background-color;
    color: $rbt-token-disabled-color;
    pointer-events: none;
  }

  &-removeable {
    cursor: pointer;
    padding-right: 21px;
  }

  &-active {
    background-color: $rbt-token-active-background-color;
    color: $rbt-token-active-color;
    outline: none;
    text-decoration: none;
  }

  & &-remove-button {
    bottom: 0;
    color: inherit;
    font-size: inherit;
    font-weight: normal;
    opacity: 1;
    outline: none;
    padding: 3px 7px;
    position: absolute;
    right: 0;
    text-shadow: none;
    top: -2px;
  }
}

div.pagination-text {
  line-height: 2rem;
}

div.pagination-link {
  color: $textSecondary;
  font-size: 1.75rem;
  line-height: 1.75rem;
  cursor: pointer;
  display: inline-block;

  &:hover {
    text-decoration: none;
  }

  &.disabled {
    color: $light-gray;
    cursor: default;
  }
}
