$light-white: #f7f7f9;
$light-gray: #a3abb9;
$light-blue: #c8f4ff;
$medium-blue: #3f6296;
$light-green: #e3f4f2;
$green: #9dd7d3;
$darker-green: #3bafa8;
$main-red: #dc1f27;
$dark-gray: #5d6c80;
$lighter-gray: #eef1f7;
$default-purple: #aa7bbc;
$default-orange: #feb45a;
$default-red: #fe605a;
$default-green: #57b7b1;
$darker-red: #d0021b;
$dark-blue: #193a6a;
$dark-black: #2d3d4c;
$error-red: #dc3545;

$page-bg-color: $lighter-gray;
$textPrimary: #1a252b;
$textSecondary: #6b778b;
$link-color: #3d76c8;
$bg-primary: #3d76c8;
$bg-tactic: #3bafa8;
$bg-verification-all-nav: $medium-blue;
$textDisabledSecondary: #a3abb9;
$table-active: rgba(#3d76c8, 0.15);
$bg-verification-bulk-active: $light-blue;

$border-secondary: #ccd3d8;

$success-color: $default-green;

$suggested-color: $darker-red;
$flagged-color: #3d76c8;
$reported-color: $default-orange;
$approved-color: #7bd7b3;
$removed-color: #ed1c30;
$pending-color: $default-purple;

$navBgColor: #eef1f7;

.suggested-color {
  color: $suggested-color;
}
.flagged-color {
  color: $flagged-color;
}
.reported-color {
  color: $reported-color;
}
.approved-color {
  color: $approved-color;
}
.removed-color {
  color: $removed-color;
}
.pending-color {
  color: $pending-color;
}

.bg-primary {
  background-color: $bg-primary !important;
}

.table-active {
  background-color: $table-active !important;
}

.btn-outline-success {
  border-color: $success-color;
}

.bg-nav-color {
  background-color: $navBgColor !important;
}

.bg-lighter-gray {
  background-color: $lighter-gray !important;
}
