@import '../../colors';

.loading-button {
  min-width: 130px;
  border-radius: 23px;
}

.profile-img {
  width: 36px;
  height: 36px;
}

.profile-img-placeholder {
  width: 36px;
  height: 36px;
  font-size: 24px;
  background-image: linear-gradient(133deg, #374685, #77c2c1 97%);
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  position: relative;

  .temp-verified {
    position: absolute;
    top: -6px;
    right: -6px;
    width: 17px;
    height: 17px;
    background-color: $approved-color;

    svg {
      width: 10px;
      position: absolute;
      top: -3px;
      left: 4px;
    }
  }
}


.f1-toggle-button {
  /* REACTSTRAP BUTTONDROPDOWN  */
  .dropdown-toggle {
    border-radius: 0.125rem !important;
    border-color: $border-secondary !important;
    background-color: transparent;
    padding-right: 40px;
    padding-left: 10px;
    text-align: left;
    justify-content: space-between;
    align-items: center;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(77, 144, 254, .5) !important;
    }

    &:active,
    &:hover {
      border-color: $link-color;
      background-color: transparent !important;
      color: $textSecondary !important;
      box-shadow: 0 0 0 0.2rem rgba(77, 144, 254, .5) !important;
    }

    &:after {
      display: none;
    }

    .dropdown-arrow {
      position: absolute;
      top: 4px;
      right: 4px;
      text-align: right;
      margin-right: 4px;
      padding-left: 12px;

      > span {
        color: $border-secondary;
      }

      > svg {
        font-size: 21px;
        vertical-align: middle;
        margin-left: 4px;
      }
    }
  }

  .dropdown-item:hover {
    background-color: $page-bg-color;
  }

  .show > .btn-outline-secondary.dropdown-toggle {
    border-color: $link-color;
    background-color: transparent !important;
    color: $textSecondary !important;

    .dropdown-arrow > svg {
      transform: rotate(180deg);
    }
  }
}

.toast-container {
  position: absolute;
  top: 100px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 100;

  .toast {
    width: 350px;
    background-color: lighten($removed-color, 50%);

    .toast-header {
      background-color: lighten($removed-color, 30%);
      color: $textPrimary;
    }

    .toast-body {
      background-color: lighten($removed-color, 40%);
      color: $textPrimary;
    }

    &.success {
      background-color: $success-color;

      .toast-header {
        background-color: $success-color;
      }

      .toast-body {
        background-color: lighten($success-color, 30%);
      }
    }
  }
}

.f1-badge {
  &.badge {
    height: 29px;
    background-color: $navBgColor !important;
    color: $link-color;
    white-space: normal;

    &.clickable {
      cursor: pointer;
    }

    svg {
      color: $link-color !important;
      border-color: transparent !important;
    }

    &:hover {
      background-color: $bg-primary !important;
      color: #fff !important;

      svg {
        color: #fff !important;
      }
    }

    &.active {
      background-color: $bg-primary !important;
      color: #fff !important;

      svg {
        color: #fff !important;
      }

      &:hover {
        background-color: lighten($bg-primary, 5%) !important;
        color: #fff !important;

        svg {
          color: #fff !important;
        }
      }
    }

    &.green {
      background-color: $light-green !important;
      color: $darker-green !important;

      svg {
        color: $darker-green !important;
      }

      &:hover {
        background-color: darken($light-green, 5%) !important;
      }
    }
  }
}

.row-toggler {
  width: 17px;
  height: 17px;
  padding: 0 !important;
  margin-right: 10px !important;
  vertical-align: text-bottom !important;
  font-size: 11px;

  &.lg {
    width: 27px;
    height: 27px;
    font-size: 17px;
  }
}
